.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
  background: #f4f4f6;
}

nz-content {
  padding-left: 50px;
  padding-right: 50px;
}

.margint {
  margin-top: 1em;
}
