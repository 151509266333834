::ng-deep nz-switch {
  margin-right: 0.5em;
}

.draft-entry td:first-child {
  border-left: 2px solid var(--color-1); /* Replace with your desired color and width */
}
.deleted-draft-entry td:first-child {
  border-left: 2px solid #dd6153; /* Replace with your desired color and width */
}
.edit-btn {
  margin-left: 1.5em;
  cursor: pointer;
}

.expandable-row {
  cursor: pointer;
}
