.required-icon {
  color: var(--red);
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.titleBar {
  display: flex;
  align-items: center; /* Center items vertically */
}

.required-asterisk {
  color: var(--red);
  margin-left: 0.25rem; /* Adds a small space between label and asterisk */
}

.titleBar::before {
  content: ""; /* Add content to the pseudo-element */
  width: 5px; /* Set the width of the bar */
  height: 30px; /* Set the height of the bar */
  background-color: var(--color-1); /* Set the color of the bar */
  margin-right: 10px; /* Add some space between the bar and the text */
}

.edit-user-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-right: 100px;
}

/*Loading*/
::ng-deep.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--color-1) !important;
}
.loading-text {
  margin-top: 10px;
  font-size: 20px !important;
  font-weight: 400 !important;
  font-family: "Inter", sans-serif !important;
}

.edit-user-form {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  outline: 5px var(--color-0);
  width: 600px;
}

.buttons {
  margin-top: 30px;
  padding-bottom: 20px;
  padding-right: 20px;
}

.save-button {
  background-color: var(--color-0) !important;
  border: 1px solid var(--color-0) !important;
  color: white !important;
  margin-right: 20px;
}

.save-button:disabled {
  cursor: not-allowed;
  background-color: #b5b5b5 !important;
  border: 1px solid #b5b5b5 !important;
  color: white !important;
}

.cancel-button {
  background-color: var(--red) !important;
  border: 1px solid var(--red) !important;
  color: white !important;
}

.email-button {
  background-color: #ffd470 !important;
  color: white !important;
  /* padding-top: 30px !important;
  padding-bottom: 30px !important; */
}

.mail-section {
  margin-top: 10px;
  padding-right: 20px;
}

.add-patient-body {
  /* border: 1px solid green; */
  padding-left: 15vw;
  padding-right: 15vw;
  height: 100%;
}

.add-patient-row {
  margin-bottom: 1.5em;
}

.add-patient-col {
  margin-left: 1em;
  margin-right: 1em;
  height: 35px !important;
}

.add-patient-input {
  width: 100%;
  color: black;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}

select.ng-valid.ng-touched {
  color: black;
}

input {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

input[type="date"] {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

::placeholder {
  color: #c2c2c2;
}

select.ng-invalid.ng-touched,
.nz-select.ng-invalid.ng-touched,
.ngx-intl-tel-input.ng-invalid.ng-touched {
  border: 1px solid #c0392b !important; /* Red border for invalid dropdowns */
  border-radius: 2px !important;
}

.submitted {
  .ng-invalid {
    border: red 1px solid;
    border-radius: 2px;
  }
}

::ng-deep .ant-select-selector {
  width: 100% !important;
  color: black !important;
  border: 1px solid #d8d8d8 !important;
  border-radius: 4px !important;
  padding-left: 10px !important;
  min-height: 35px !important;
}

.multiselector {
  margin-left: 1em !important;
  margin-right: 1em !important;
}
::ng-deep section ::ng-deep .tel-form {
  height: 35px !important;
}

::ng-deep
  section
  ::ng-deep
  .tel-form
  ::ng-deep
  mat-form-field.prefix-form-field {
  width: 210px !important;
  padding-left: 0px !important;
}

::ng-deep
  section
  ::ng-deep
  .tel-form
  ::ng-deep
  mat-form-field
  ::ng-deep
  .mdc-text-field {
  height: 35px !important;
  align-items: center !important;
}

/* General error message style */
.error-message {
  display: block; /* Ensure error messages are block elements */
  color: var(--red); /* Red for errors */
  font-size: 12px; /* Smaller font size */
  margin-top: 2px; /* Reduced space between input and error */
}

::ng-deep
  div:where(.swal2-container)
  button:where(.swal2-styled).swal2-confirm {
  background-color: var(--color-1);
}

nz-select {
  margin: 0px !important;
}
