::ng-deep nz-switch {
  margin-right: 0.5em;
}

.draft-entry {
  border-right: 2px solid var(--color-1);
  border-top: 2px solid var(--color-1);
  border-bottom: 2px solid var(--color-1);
}

.first-draft-entry {
  border-left: 2px solid var(--color-1);
}

.edit-btn {
  margin-left: 1em;
  cursor: pointer;
}

.expandable-row {
  cursor: pointer;
}

.bold {
  font-weight: 500;
}

.actions {
  margin-left: 1em;
  margin-right: 1em;
  float: right;
}

.item-submitted {
  .item.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}
