::ng-deep .ant-modal-footer .ant-btn-dangerous {
  background-color: var(--red) !important; /* Ensures the background is red */
  color: white !important; /* Ensures the text color is white */
  border: none !important; /* Removes border if necessary */
}

::ng-deep .ant-modal-footer .ant-btn-dangerous:hover {
  background-color: darkred !important; /* Darker shade on hover */
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc {
  /* the calendar root */
  /* max-width: 1100px; */
  max-height: 75vh;
  margin: 0 auto;
  max-width: 75vw;
}
::ng-deep .fc-theme-standard .fc-scrollgrid {
  background: white !important;
  max-width: 100%;
}
::ng-deep .fc .fc-button-primary {
  background-color: var(--color-4) !important;
  border: 1px solid var(--color-4) !important;
  color: black !important;
}

::ng-deep .fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: var(--color-1) !important;
  border: 1px solid var(--color-1) !important;
  color: white !important;
}

::ng-deep .fc .fc-button-primary:disabled {
  cursor: not-allowed;
  background-color: #a0a0a0 !important;
  border: 1px solid #a0a0a0 !important;
  color: black !important;
}

::ng-deep .fc-col-header-cell-cushion {
  color: var(--color-1) !important;
}

::ng-deep .fc-daygrid-day-number {
  color: var(--color-1) !important;
}
/* ::ng-deep .fc-event {
  background-color: var(--color-1) !important;
  border: 1px solid var(--color-1) !important;
  color: white !important;
} */

/* ::ng-deep .fc-list-event {
  background-color: white !important;
  color: black !important;
}
*/
::ng-deep .fc-list-day-side-text {
  color: var(--color-1) !important;
}

::ng-deep .fc-list-day-text {
  color: var(--color-1) !important;
}

/* ::ng-deep .fc-event-main .ng-star-inserted {
  color: white !important;
}

::ng-deep .fc-h-event {
  background-color: var(--color-1) !important;
  border: 1px solid var(--color-1) !important;
  color: white !important;
} */

/* dots on list events */
/* ::ng-deep .fc .fc-list-event-dot {
  border: calc(var(--fc-list-event-dot-width) / 2) solid
    var(--color-3) !important;
} */

::ng-deep .fc-h-event .fc-event-main {
  color: #000;
}

::ng-deep .fc-popover {
  z-index: 1000 !important;
}
