.weight-submitted {
  .weight.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}

.text-area {
  border: 1px solid #d9d9d9;
  padding: 6px 11px;
  border-radius: 4px;
  width: 100%;
}
