.resolved-container {
  margin-top: 2em;
  padding: 1.5em;
  border: 2px solid rgba(100, 100, 100, 0.2);
  border-radius: 8px;
}

.empty-table {
  margin-bottom: 3em;
}

.resolved-submitted {
  .resolved.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}

.resolved-submitted {
  .resolved.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}
