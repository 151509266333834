.patients-header-body {
  display: flex;
  position: fixed;
  background-color: white;
  min-height: 120px !important;
  max-height: 120px !important;
  width: 100%;
  z-index: 1;
  padding: 15px 30px;
  font-size: 13px;
  border-bottom: 2px solid var(--color-5) !important;
}

.inpatients-header-body {
  display: flex;
  position: fixed;
  background-color: white;
  min-height: 120px !important;
  max-height: 120px !important;
  width: 100%;
  z-index: 1;
  padding: 15px 30px;
  font-size: 13px;
  border-bottom: 2px solid var(--color-5) !important;
}

.header-patients {
  cursor: pointer;
  color: var(--label-color);
  font-size: 14px;
  font-weight: 500;
  margin-right: 0.5em;
}

.header-patients:hover {
  cursor: pointer;
  color: var(--color-1);
  font-size: 14px;
  text-decoration: underline;
  margin-right: 0.5em;
}

.header-details {
  display: flex;
}

.header-details-2 {
  margin-top: 0.5em;
  display: flex;
}

.divider {
  margin-left: 5px;
  margin-right: 5px;
  color: #d8d8d8;
}

.split-lines {
  display: block !important;
}

.add-episode {
  height: 35px;
  border: 1px solid var(--color-1) !important;
  border-radius: 4px;
  background: var(--color-1) !important;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-episode:hover {
  cursor: pointer;
}

.fullname {
  font-size: 17px;
  color: var(--label-color);
  font-weight: 600;
}

.information-icon {
  margin-left: 0.3em;
  color: var(--color-2);
  margin-top: auto;
  margin-bottom: auto;
  /* scale: 0.8; */
}

.information-icon:hover {
  cursor: pointer;
  color: var(--color-1);
}

::ng-deep
  div:where(.swal2-container)
  button:where(.swal2-styled).swal2-confirm {
  background-color: var(--color-1);
}

.discard-button {
  border-radius: 5px;
  border: 1px solid red !important;
  background: #fcf0f0 !important;
}

.discard-button:hover {
  background: #fcd6d6 !important;
}

.preview-button {
  border-radius: 5px;
  margin-left: 1em;
  background: var(--color-4) !important;
}

.preview-button:hover {
  background-color: var(--color-3) !important;
}

.back-button {
  border-radius: 5px;
  border: 1px solid orange !important;
  background: #fdf3db !important;
}

.back-button:hover {
  background: #ffd470 !important;
}

.save-button {
  border-radius: 5px;
  margin-left: 1em;
  border: 1px solid var(--color-1) !important;
  background: var(--color-6) !important;
}

.save-button-hover:hover {
  background: #53c9c1 !important;
}

.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-pp {
  padding-right: 20px;
}

.profilePicture {
  border: 1px solid var(--color-2);
}

@media only screen and (max-width: 1279.99px) {
  .btn-text {
    display: none !important;
  }
}

.header-icon {
  scale: 1 !important;
}
