.empty-table {
  margin-bottom: 3em;
}

.outcome-submitted {
  .outcome.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}

.reactions-submitted {
  .reaction.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}
