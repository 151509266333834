.ant-layout-header {
  font-weight: 500;
  font-size: 18px;
  background-color: #fbfbfb;
  background: #fbfbfb;
  padding: 0px !important;
}

nz-layout {
  background: #fbfbfb;
}

::ng-deep
  .ant-table.ant-table-middle
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px !important;
}

.open-visit {
  margin-left: 1.5em;
  cursor: pointer;
}

.discard-visit {
  cursor: pointer;
}

::ng-deep
  div:where(.swal2-container)
  button:where(.swal2-styled).swal2-confirm {
  background-color: var(--color-1);
}

.titleText {
  font-size: 10px;
}
