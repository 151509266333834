.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background: #f4f4f6;
}

nz-content {
  padding-left: 50px;
  padding-right: 50px;
}

nz-header {
  height: 100%;
}

.summary-container {
  min-height: 100vh;
}

.view-icon {
  margin-left: 10px;
  cursor: pointer;
}

.section-header {
  padding-top: 1em;
  padding-bottom: 1em;
  border: 2px solid #d8d8d8;
  background-color: #fafafa;
  color: black;
  line-height: unset !important;
}

.summary-body {
  padding-top: 2em;
  border-left: 2px solid #d8d8d8;
  border-right: 2px solid #d8d8d8;
  border-bottom: 2px solid #d8d8d8;
}

.info {
  width: 100%;
  padding: 1em;
  background-color: transparent;
  font-weight: 400;
}

.allergy-description {
  margin-top: 20px;
}

.f-500 {
  font-weight: 500;
}

:host ::ng-deep .mat-expansion-panel-header {
  height: 100%;
}

:host ::ng-deep .mat-expansion-panel-header.mat-expanded {
  height: 100%;
}

:host ::ng-deep .mat-expansion-panel {
  margin-top: 1px;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.entry-container {
  margin-top: 1px !important;
  display: block;
  margin: 0;
  overflow: hidden;
  transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  background: var(--mat-expansion-container-background-color);
  color: var(--mat-expansion-container-text-color);
  border-radius: var(--mat-expansion-container-shape);
  padding: 0 24px;
  font-family: var(--mat-expansion-header-text-font);
  font-size: var(--mat-expansion-header-text-size);
  font-weight: var(--mat-expansion-header-text-weight);
  line-height: var(--mat-expansion-header-text-line-height);
  letter-spacing: var(--mat-expansion-header-text-tracking);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.patient-name {
  font-weight: 600;
  font-size: 18px;
}

.patient-details {
  font-weight: 400;
  font-size: 14px;
}

.patient-documents {
  padding-top: 0.5em;
  margin-top: 0.5em;
  width: 100%;
  border-top: 1px solid #d8d8d8;
  font-weight: 400;
  font-size: 14px;
}

.mr-5 {
  margin-right: 5px;
}
.mpt-5 {
  margin-top: 0.5em;
  padding-top: 0.5em;
}
