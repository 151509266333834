textarea {
  width: 100%; /* Ensure textarea is also 100% width */
  box-sizing: border-box; /* Include padding and border in width calculation */
}

.nz-switch {
  margin-top: 8px; /* Align toggle with other inputs */
}

input,
textarea,
.nz-select {
  padding: 0 11px; /* Consistent padding for all input types */
  border: 1px solid #d9d9d9; /* Consistent border */
  height: 32px; /* Consistent height */
}

.form-row {
  margin-top: 1em; /* Space between rows */
}

.footer {
  margin-top: 2em; /* Space above footer */
}

.save-btn {
  margin-right: 1em; /* Space to the right of save button */
}

.add-btn {
  margin-bottom: 1em; /* Space below add button */
}

/* Additional styling for the modal */

/* General button styling */

/* Additional styles for validation */

/* Media queries */

/* Other existing styles */
.material-icons {
  font-size: 22px;
}

.asterisk {
  color: var(--red);
}

.color-options {
  display: flex;
  gap: 10px;
}

.color-options label {
  position: relative;
  cursor: pointer;
}

.color-options input[type="radio"] {
  display: none;
}

.color-options .color-circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.color-options input[type="radio"]:checked + .color-circle {
  border-color: black;
}
.error-message {
  color: var(--red);
  font-size: 12px;
  margin-top: 5px;
}

.info-message {
  color: #505253;
  font-size: 12px;
  margin-top: 5px;
}

::ng-deep .ant-switch,
nz-switch {
  flex-shrink: 0 !important; /* Prevent the switch from shrinking or growing */
  width: auto !important; /* Ensure the switch takes only the space it needs */
}

.color-circle.disabled {
  opacity: 0.2; /* Make the circle appear faded/disabled */
  cursor: not-allowed;
}
