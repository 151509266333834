.empty-table {
  margin-bottom: 3em;
}

.vital-sign-submitted {
  .vital-sign.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}
