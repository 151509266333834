input {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  height: 32px;
  /* margin-left: 1em; */
}

nz-select {
  /* margin-left: 1em; */
}

.tubeCharacteristics-submitted {
  .tubeCharacteristics.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}
