textarea {
  padding: 0 11px;
  border: 1px solid #d9d9d9;
}

.laboratory-submitted {
  .laboratory.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}

::ng-deep .ant-checkbox-checked ::ng-deep .ant-checkbox-inner {
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
}
