::ng-deep nz-switch {
  margin-right: 0.5em;
}

.table-margin {
}

.draft-entry td:first-child {
  border-left: 2px solid var(--color-1); /* Replace with your desired color and width */
}

.edit-btn {
  margin-left: 1.5em;
  cursor: pointer;
}

.expandable-row {
  cursor: pointer;
}

tr:hover {
  cursor: pointer;
}

.document-view {
  border: 1px solid lightgray;
  /* border-radius: 10px; */
  min-width: 100% !important;
}

.row {
  height: 100%;
}

.pdf-viewer {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.preview-content {
  height: 60vh;
  width: 100%;
}

.preview-title {
  font-size: 18px;
  font-weight: 500;
  padding: 1em;
  height: 64px;
  border-bottom: 1px solid lightgray;
  background: var(--color-6) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download-btn:hover {
  cursor: pointer;
}

.anticon {
  display: block;
  margin: 1em;
}

.folder-view-icon.anticon {
  scale: 2.5 !important;
}

.preview {
  margin-bottom: 2em;
}

.modal-pdf {
  min-height: 70vh !important;
  min-width: 100% !important;
}

.selected-preview {
  background-color: var(--color-7) !important;
}
