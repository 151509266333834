.ant-layout-header {
  font-weight: 600;
  font-size: 20px;
  background-color: #f4f4f6;
}

nz-select {
  margin-left: 0.5em !important;
}

nz-layout {
  background: #f4f4f6;
}

nz-content {
  padding-left: max(3em, 60px);
  padding-right: max(3em, 60px);
  flex-direction: column !important;
}

.pd {
  margin-bottom: 1em;
}

.pdd {
  margin-left: 0.5em;
  width: 100% !important;
}

.last-row {
  margin-bottom: 2em !important;
}

::ng-deep
  .ant-select:not(.ant-select-customize-input)
  ::ng-deep
  .ant-select-selector {
  border-radius: 5px;
}

.nt {
  margin-left: 0.5em;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 0px 11px;
}

.ng-invalid {
  border: 1px solid red;
  border-radius: 5px;
}

.toggle {
  width: auto !important;
}
