/* detailed-patient.component.css */
.patient-details-container {
  padding-top: 30px;
  padding-left: 50px;
  padding-bottom: 30px;
  margin: auto;
  height: auto;
}

.data-container {
}
.form-row {
  display: flex;
  justify-content: space-between;
}

.titleBar {
  display: flex;
  align-items: center;
}
.titleBar::before {
  content: "";
  width: 5px;
  height: 30px;
  background-color: var(--color-1);
  margin-right: 10px;
}

.infoBar {
  display: flex;
  align-items: center;
  margin-left: -15px;
}
.infoBar::before {
  content: "";
  width: 5px;
  height: 30px;
  background-color: #53c9c1;
  margin-right: 10px;
}

.personal-info,
.address-info,
.documentation-info {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.info-item {
  min-width: 350px;
  margin-bottom: 10px;
}

label {
  font-weight: bold;
  margin-right: 8px;
}

span {
  color: #333;
}
