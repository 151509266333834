.patients-search {
  /* border: 1px solid blue; */
  margin-top: 2em;
  margin-bottom: 2em;
  height: 10%;
}

.patients-table {
  /* border: 1px solid yellow; */
  height: 90%;
  min-width: 100%;
}

.mat-mdc-form-field-focus-overlay {
  background-color: var(--red) !important;
}

.patients-search-input {
  height: 100% !important;
  margin-right: 3em;
}

@media (max-width: 1140px) {
  .patients-search-input {
    margin-right: 1em !important;
  }
}

.patients-search-buttons {
  height: 35px !important;
  margin-left: 2em;
}

.mat-mdc-input-element {
  height: 35px !important;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}

::placeholder {
  color: #c2c2c2;
}

input[type="date"] {
  font-family: "Inter", sans-serif !important;
}

.clear-button {
  height: 100%;
  border: 1px solid var(--color-0);
  border-radius: 4px;
  background-color: white;
  color: var(--color-0);
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-align {
  display: flex !important;
  align-items: center !important;
  justify-content: space-evenly !important;
}

.register-button,
.export-button {
  height: 100%;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
}

.register-button {
  background-color: var(--color-1);
  border: 1px solid var(--color-1);
}

.export-button {
  background: #f3f3f3 !important;
  border: 1px solid black !important;
  color: black;
}

.register-button:hover,
.export-button:hover,
.clear-button:hover {
  cursor: pointer;
}

.add-button {
  border: 1px solid var(--color-0);
  border-radius: 15px;
  background-color: white;
  color: var(--color-0);
  padding: 5px;
  font-size: 12px;
  min-width: calc(45% + 5px);
}

.add-button:hover {
  color: white;
  background-color: var(--color-0);
  cursor: pointer;
}

.discharge-button {
  border: 1px solid var(--red);
  border-radius: 15px;
  background-color: var(--red);
  color: white;
  padding: 5px;
  font-size: 12px;
  min-width: calc(45% + 5px);
}

.discharge-button:hover {
  background-color: var(--red-hover);
  cursor: pointer;
}

.waitinglist-button {
  background-color: var(--waiting-list);
  color: white;
  border: 1px solid var(--waiting-list);
  border-radius: 15px;
  padding: 5px;
  font-size: 12px;
  min-width: 45%;
  min-width: 115px !important;
  width: 115px;
  max-width: 115px;
}

.waitinglist-button:hover {
  background-color: var(--waiting-list-hover);
  color: white;
  cursor: pointer;
}

.preapprove-button {
  background-color: var(--color-1);
  color: white;
  border: 1px solid var(--color-0);
  border-radius: 15px;
  padding: 5px;
  font-size: 12px;
  min-width: 115px !important;
  width: 115px;
  max-width: 115px;
}

.preapprove-button:hover {
  background-color: var(--color-0);
  color: white;
  cursor: pointer;
}

.full-width {
  margin-left: 0px;
  min-width: calc(90% + 5px) !important;
}

.patients-content-paginator-hide {
  display: none !important;
}

.parent-body {
  height: 100%;
}

.patients-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  /* border: 1px solid red; */
}

::ng-deep .mat-mdc-paginator-outer-container {
  margin-top: 0em;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

input {
  color: black !important;
}

textarea {
  border: 1px solid #d9d9d9;
  padding-left: 11px;
}

::ng-deep .mat-column-admission-actions {
  /* max-width: 270px !important;
  width: 270px !important;
  min-width: 270px !important; */
  max-width: 100px !important;
  width: 100px !important;
  min-width: 100px !important;
  padding-right: 0px !important;
}

::ng-deep .mat-column-location {
  max-width: 300px !important;
  width: 300px !important;
  min-width: 100px !important;
}

::ng-deep .mat-column-patientFullName {
  max-width: 200px !important;
  width: 200px !important;
  min-width: 100px !important;
}

::ng-deep .mat-column-sepsis {
  max-width: 150px !important;
  width: 150px !important;
  min-width: 150px !important;
}

nz-progress {
  width: 100% !important;
}

.bed-submitted {
  .ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}

.discharge-submitted {
  .ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}

.reactions-submitted {
  .reaction.ng-invalid {
    border: red 1px solid !important;
    border-radius: 2px;
  }
}

::ng-deep
  div:where(.swal2-container)
  button:where(.swal2-styled).swal2-confirm {
  background-color: var(--color-1);
}

.patient-dropdown {
  width: 100%;
}

.pending {
  font-weight: 500;
  color: #fdc55d;
}

.waitinglist {
  font-weight: 500;
  color: var(--waiting-list);
}

.admitted {
  font-weight: 500;
  color: var(--admitted);
}

.approved {
  font-weight: 500;
  color: var(--approved);
}

.discharged {
  font-weight: 500;
  color: var(--discharged);
}

.rejected {
  font-weight: 500;
  color: var(--rejected);
}

.preapproved {
  background-color: #fff9d4;
  border-radius: 15px;
  padding: 0.5em;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.approved:hover {
  font-size: 16px;
}

.admitted:hover {
  font-size: 16px;
}

.rejected:hover {
  font-size: 16px;
}

.approved i,
.rejected i,
.admitted i {
  vertical-align: middle;
}

.moderate-rv {
  color: var(--orange);
}

.severe-rv {
  color: var(--red);
}

::ng-deep .mat-mdc-table {
  overflow-x: auto;
}

.search-button {
  height: 100%;
  border: 1px solid var(--color-0);
  border-radius: 4px;
  background-color: var(--color-0);
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-button:hover {
  cursor: pointer;
}

::ng-deep .ant-checkbox-checked ::ng-deep .ant-checkbox-inner {
  background-color: var(--color-1) !important;
  border-color: var(--color-1) !important;
}

::ng-deep .ant-checkbox-input:focus + ::ng-deep .ant-checkbox-inner {
  border-color: var(--color-1) !important;
}

::ng-deep .ant-checkbox-input:hover + ::ng-deep .ant-checkbox-inner {
  border-color: var(--color-1) !important;
}
.action-buttons-cell {
  display: flex;
  gap: 2px; /* Further reduce the gap between buttons */
  justify-content: center; /* Center the buttons */
  align-items: center; /* Center the buttons vertically */
  padding-right: 5px; /* Reduce padding to the right */
  margin-right: 0.5em;
}

/* .add-button, */
.discharge-button,
.preapprove-button {
  flex: 1;
  min-width: unset; /*Remove min-width*/
  border-radius: 15px;
}

.add-button {
  color: var(--color-0);
  border: 1px solid var(--color-0);
  border-radius: 15px;
  background-color: transparent;
  padding: 5px;
  min-width: 75%;
  font-size: 12px;
}

.add-button:hover {
  color: white;
  background-color: var(--color-0);
  cursor: pointer;
}

.discharge-button {
  border: 1px solid var(--red);
  background-color: var(--red);
  color: white;
}

.discharge-button:hover {
  background-color: var(--red-hover);
  cursor: pointer;
}

.preapprove-button {
  background-color: var(--color-1);
  color: white;
  border: 1px solid var(--color-0);
}

.preapprove-button:hover {
  background-color: var(--color-0);
  color: white;
  cursor: pointer;
}

.no-patients {
  margin-top: 15em;
  font-size: large;
}

.ml-1 {
  margin-left: 1em;
}

.anticon {
  margin-left: 2px;
}

.anticon:hover {
  cursor: pointer;
}

.custom-icon svg {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 1279.99px) {
  .btn-text {
    display: none !important;
  }
}

[nz-icon] {
  font-size: 20px;
}

.admission-spinner {
  margin-top: 3em;
}
.firstName,
.lastName {
  font-weight: 500;
  /* color: #393e67; */
}

.dateOfBirth,
.dateOfApplication,
.age,
.daysFromFirstRegistration,
.fileRatio,
.documentNumber {
  font-weight: 400;
  font-size: smaller;
  /* color: #a098ae; */
}
mat-row:hover {
  background-color: var(--light-gray);
  cursor: pointer;
}

.search-params-row {
  height: 100%;
  align-items: center;
}

.search-params-row-2 {
  margin-top: 10px;
}

.dob-input {
  width: 100%;
  height: 32px !important;
}

.expand-btn {
  scale: 0.5;
}

.sort-btn {
  margin-left: 5px;
  height: 16px;
  width: 16px;
  font-size: 16px !important;
}
