.left-half {
  min-height: 100vh;
}

.left-image {
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
}

@media only screen and (max-width: 1279.99px) {
  .left-image {
    width: 100%;
    max-height: 400px;
    min-height: 400px;
  }

  .left-half {
    min-height: 400px;
  }
}

.logo {
  max-width: 300px;
  margin-bottom: 2em;
}

.login-text {
  font-size: 16px;
  font-weight: 600;
}

.email {
  margin-top: 1em;
  width: 100%;
  border-radius: 5px;
  padding: 14px;
  border: 1px solid #ccc;
  min-height: 52px;
}

.login-btn {
  margin-top: 1em;
  background-color: #317f88;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  font-family: inherit;
  min-height: 52px;
}

.invalid-login-btn {
  margin-top: 1em;
  background-color: #93c3c9;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 10px;
  cursor: not-allowed;
  width: 100%;
  font-size: 15px;
  font-family: inherit;
  min-height: 52px;
}

.back-btn {
  margin-top: 1em;
  background-color: transparent;
  border: 0.1px solid #317f88 !important;
  border-style: outset;
  color: black;
  padding: 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  min-height: 52px;
  max-height: 52px;
  max-width: 50px;
  margin-right: 10px;
}
