.card {
  border-radius: 8px; /* Adjust the border radius as needed */
  padding: 10px 18px;
  border: 1px solid lightgray;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
  overflow: hidden; /* Hide overflow if necessary */
  width: 100%;
  min-height: 140px;
  justify-content: space-around;
  background-color: white;
}

.ward-container {
  margin-bottom: 20px;
  margin-right: 10px;
  width: 100%;
}

.card-header {
  /* display: flex; */
  align-items: center;
  margin-left: 10px;
  width: 100%;
}

.card-icon {
  font-size: 24px;
  color: var(--color-2);
}

.card-ward {
  font-weight: 500;
  font-size: large;
}

.card-availability {
  font-size: 15px;
}

.card-title {
  font-size: 18px;
  margin-left: 10px;
}

.card-body {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
}

.card-value,
.card-percentage,
.card-subvalue {
}

.card-percentage {
  font-size: 24px;
}

.decrease {
  color: #ff0000; /* Red for decrease */
}

.increase {
  color: var(--color-2); /* Green for increase */
}

.card-tile {
  font-size: smaller;
}

.card-value-percentage {
  /* display: flex; */
  align-items: flex-end;
  justify-content: flex-end;
}

.card-content {
  margin-top: 15px;
  margin-bottom: 5px;
}
