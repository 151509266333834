.patients-search {
  /* border: 1px solid blue; */
  margin-top: 2em;
  margin-bottom: 2em;
  height: 10%;
}

.patients-table {
  /* border: 1px solid yellow; */
  height: 90%;
  min-width: 100%;
}

.mat-mdc-form-field-focus-overlay {
  background-color: var(--red) !important;
}

.patients-search-input {
  height: 35px !important;
  margin-right: 3em;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1140px) {
  .patients-search-input {
    margin-right: 1em !important;
  }
}

.patients-search-buttons {
  height: 35px !important;
  margin-right: 1em;
}

.mat-mdc-input-element {
  height: 35px !important;
  color: #c2c2c2;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding-left: 10px;
}

::placeholder {
  color: #c2c2c2;
}

::ng-deep.mat-mdc-input-element {
  color: black !important;
}

.register-button {
  height: 35px;
  border: 1px solid var(--color-1);
  border-radius: 4px;
  background-color: var(--color-1);
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-button:disabled {
  border: 1px solid lightgray;
  background-color: lightgray;
  cursor: not-allowed;
}

.clear-button:hover {
  cursor: pointer;
}

.register-button:hover {
  cursor: pointer;
}

.um-action-button {
  color: var(--color-0);
  border: 1px solid var(--color-0);
  border-radius: 15px;
  background-color: transparent;
  padding: 7px;
  min-width: 50%;
  font-size: 12px;
  line-height: normal;
}

.btn-text {
  line-height: normal;
}

.um-action-buttons-gap {
  display: flex;
  gap: 10px;
}

.um-action-button.edit:hover {
  background-color: var(--color-0);
  color: white;
  cursor: pointer;
}

.um-action-button.delete:hover {
  background-color: var(--red);
  border-color: var(--red);
  color: white;
  cursor: pointer;
}

::ng-deep .mat-column-umactions {
  max-width: 220px !important;
  width: 220px !important;
  min-width: 150px !important;
  padding-right: 20px !important;
}

::ng-deep .mat-column-phoneNumber {
  max-width: 200px !important;
  width: 100px !important;
  min-width: 100px !important;
}

.patients-content-paginator-hide {
  display: none !important;
}

.parent-body {
  height: 100%;
}

mat-row:hover {
  background-color: var(--light-gray);
}

.patients-container {
  margin: 20px 30px;
  /* border: 1px solid red; */
}

::ng-deep .mat-mdc-paginator-outer-container {
  margin-top: 0em;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
@media only screen and (max-width: 1279.99px) {
  .btn-text {
    display: none !important;
  }
}

[nz-icon] {
  font-size: 20px;
}

.userManagement-spinner {
  margin-top: 3em;
}

nz-select {
  margin: 0px !important;
}
.no-patients {
  margin-top: 15em;
  font-size: large;
}
.search-button {
  height: 35px;
  border: 1px solid var(--color-0);
  border-radius: 4px;
  background-color: var(--color-0);
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.search-button:hover {
  cursor: pointer;
}
/* .register-button {
  height: 35px;
  border: 1px solid var(--color-1);
  border-radius: 4px;
  background-color: var(--color-1);
  color: white;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
} */
